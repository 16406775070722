/* eslint-disable */
import axios from 'axios';
import ConfigService from './ConfigService'

const url = 'api/datasource/';
  
class DataSourceService {

//*****************
//******* Get the datasource(s) associated with the specified Site
//*****************
static async dsGetBySite (siteuid) {
  const config = await this.getConfigFile()
  // debugger
  if (config.useProxy) {
    return this.dataSourceSelectBySiteUid(siteuid)
  } else {
  return new Promise(async (resolve, reject) => {
    // console.log('DataSourceService.dsGetBySite');
    // debugger
    try {
      const res = await axios.get(url+'dsgetbysite', {
      params: {
        siteuid:siteuid,
      }
      });
      const data1 = res.data
      // console.log('data1 (dsGetBySite_DT)= ',data1)
      resolve(
        data1.data
      )  
    } catch(err) {
      reject(err);
    }
  });
  }
}

//*****************
//******* Get a datasource from its Uid
//*****************
static async dsGetbyUid (datasourceuid) {
  const config = await this.getConfigFile()
  // debugger
  if (config.useProxy) {
    return this.dataSourceSelectByUid(datasourceuid)
  } else {
  return new Promise(async (resolve, reject) => {
    // debugger
    try {
      const res = await axios.get(url+'dsGetbyUid', {
      params: {
        datasourceuid:datasourceuid}
      });
      const data1 = res.data
      // console.log('data1 (siteGetbyUid)= ',data1)
      resolve(
        data1.data
      )  
    } catch(err) {
      reject(err);
    }
  });
  }
}

//******* OnTOP Proxy Service**********
//*****************
//******* Get the datasource(s) associated with the specified Site and DeviceType
//        GET /api/sites/:siteUid/dataSources
//*****************
static async dataSourceSelectBySiteUid (siteUid) {
  // console.log('DataSourceService.dataSourceSelectBySiteUid');
  return axios
      .get("api/proxy/sites/" + siteUid + "/dataSources")
      .then(response => response.data)
      .catch(error => console.error(error));
}

//******* OnTOP Proxy Service**********
//*****************
//******* Get the datasource fields associated with the specified datasource Uid
//        GET /api/dataSources/:uuid
//*****************
static dataSourceSelectByUid (dataSourceUid) {
  // console.log('DataSourceService.dataSourceSelectByUid');
  return axios
      .get("api/proxy/dataSources/" + dataSourceUid)
      .then(response => response.data)
      .catch(error => console.error(error));
}

//******* end of OnTOP Proxy Services


//*****************
//******* Get the datasource(s) associated with the specified Site and DeviceType
//*****************
static dsGetBySite_DT (siteuid, devicetypeuid) {
  return new Promise(async (resolve, reject) => {
    // debugger
    try {
      const res = await axios.get(url+'dsgetbysite_dt', {
      params: {
        siteuid:siteuid,
        devicetypeuid:devicetypeuid
      }
      });
      const data1 = res.data
      // console.log('data1 (dsGetBySite_DT)= ',data1)
      resolve(
        data1.data
      )  
    } catch(err) {
      reject(err);
    }
  });
}

//*****************
//******* Get the datasource associated with the specified Device
//*****************
static dsGetBySite_Dev (deviceuid) {
  return new Promise(async (resolve, reject) => {
    // debugger
    try {
      const res = await axios.get(url+'dsgetbydev', {
      params: {
        deviceuid:deviceuid
      }
      });
      const data1 = res.data
      // console.log('data1 (dsGetBySite_DT)= ',data1)
      resolve(
        data1.data
      )  
    } catch(err) {
      reject(err);
    }
  });
}


static async getConfigFile () {
  //*****************
  //******* Get Config File Settings
  //*****************
    try {
      const config = await ConfigService.settingsGetOverview()
      // console.log('DataSourceService.GetConfigFile: config = ', config)
      return config
    } catch (err) {
      this.error = err.message
      console.log('DataSourceService.GetConfigFile: err= ', this.error)
    }
  }
  

}
export default DataSourceService;