/* eslint-disable */
import moment from 'moment'
export default {
//**************** */
  data () {
    },
  // Date to Day of Week Substring
  // charstoreturn = number of chars to return from start
  methods: {
  GetDayofWeekName: function (indate, charstoreturn) {
    var weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";
    var n = weekday[indate.getDay()];
    return n.substring(0,charstoreturn);
  },

  // Date to beginning Date of week
  GetFirstDayofWeek: function(indate) {
    var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6
    
    var firstday = new Date(curr.setDate(first)).toUTCString();
    var lastday = new Date(curr.setDate(last)).toUTCString();
    console.log('break here')  
    return firstday
  },

  // From provided date, return an array as follows:
  // [0],[1] first day, second day of the week the input date falls in
  // [2],[3] first day, second day of the previous week relative to the 
  //   week that the input date falls in
  GetWeekRange: function(indate) {
    var curr = moment(indate) 
    var firstday = curr.clone().startOf('week')
    var lastday = curr.clone().endOf('week')
    var prevweekfirstday = firstday.clone().subtract(7,'days')
    var prevweeklastday = lastday.clone().subtract(7,'days')
    // console.log("firstday()= ", firstday.toISOString(), "firstday(true)= ", firstday.toISOString(true), " lastday= ", lastday.toISOString(true))
    // console.log("prevweekfirstday= ", prevweekfirstday.toISOString(true), " prevweeklastday= ", prevweeklastday.toISOString(true))

    return [firstday.toISOString(true), lastday.toISOString(true), prevweekfirstday.toISOString(true), prevweeklastday.toISOString(true)]
  },
  GetWeekRange2: function(indate) {
    var curr = moment(indate) 
    var firstday = curr.clone().startOf('week')
    var lastday = curr.clone().endOf('week')
    var prevweekfirstday = firstday.clone().subtract(7,'days').format('YYYY-MM-DD')
    var prevweeklastday = lastday.clone().subtract(7,'days').format('YYYY-MM-DD')
    return [firstday.format('YYYY-MM-DD'), lastday.format('YYYY-MM-DD'), prevweekfirstday, prevweeklastday]
  },
  GetMonthRange: function(indate) {
    var curr = moment(indate) 
    var thismonthfirstday = curr.clone().startOf('month')
    var thismonthlastday = curr.clone().endOf('month')
    var prevmonthfirstday = thismonthfirstday.clone().subtract(1,'months').startOf('month').format('YYYY-MM-DD')
    var prevmonthlastday = thismonthlastday.clone().subtract(1,'months').endOf('month').format('YYYY-MM-DD')
    return [thismonthfirstday.format('YYYY-MM-DD'), thismonthlastday.format('YYYY-MM-DD'), prevmonthfirstday, prevmonthlastday]
  },
  GetQuarterRange: function(indate) {
    var curr = moment(indate) 
    var thisquarterfirstday = curr.clone().startOf('quarter')
    var thisquarterlastday = curr.clone().endOf('quarter')
    var prevquarterfirstday = thisquarterfirstday.clone().subtract(1,'quarters').startOf('quarter').format('YYYY-MM-DD')
    var prevquarterlastday = thisquarterlastday.clone().subtract(1,'quarters').endOf('quarter').format('YYYY-MM-DD')
    return [thisquarterfirstday.format('YYYY-MM-DD'), thisquarterlastday.format('YYYY-MM-DD'), prevquarterfirstday, prevquarterlastday]
  },
  GetYearRange: function(indate) {
    var curr = moment(indate) 
    var thisyearfirstday = curr.clone().startOf('year')
    var thisyearlastday = curr.clone().endOf('year')
    var prevyearfirstday = thisyearfirstday.clone().subtract(1,'years').startOf('year').format('YYYY-MM-DD')
    var prevyearlastday = thisyearlastday.clone().subtract(1,'years').endOf('year').format('YYYY-MM-DD')
    return [thisyearfirstday.format('YYYY-MM-DD'), thisyearlastday.format('YYYY-MM-DD'), prevyearfirstday, prevyearlastday]
  },

  ExtendtoFullWeeks(startdate, enddate) {
    var start = moment(startdate) 
    var firstday = start.clone().startOf('week')
    var end = moment(enddate)
    var lastday = end.clone().endOf('week')
    return [firstday.format('YYYY-MM-DD'), lastday.format('YYYY-MM-DD')]
  },

  ValidateStartEndDates(startdate, enddate) {
    var start = moment(startdate) 
    var end = moment(enddate)
    const test = start.isSameOrBefore(end)
    return test
  },

  GetUsageByHourMetrics: function(chartdatay, decimal_places) {
   const reducer = (acc, curr) => acc + curr;
    const sum = chartdatay.reduce(reducer)
    const hourmin = Math.min(...chartdatay)
    const hourmax = Math.max(...chartdatay)
    const average = sum/chartdatay.length
    return [sum.toFixed(decimal_places), hourmin.toFixed(decimal_places), hourmax.toFixed(decimal_places), average.toFixed(decimal_places)]
  }, 

  GetUsageByDayMetrics: function(chartdatay, decimal_places) {
    const reducer = (acc, curr) => acc + curr;
      const sum = chartdatay.reduce(reducer)
      const daymin = Math.min(...chartdatay)
      const daymax = Math.max(...chartdatay)
      const average = sum/chartdatay.length
      return [sum.toFixed(decimal_places), daymin.toFixed(decimal_places), daymax.toFixed(decimal_places), average.toFixed(decimal_places)]
    },

  GetUsageByWeekMetrics: function(chartdatay, decimal_places) {
  const reducer = (acc, curr) => acc + curr;
    const periodtotal = chartdatay.reduce(reducer)
    const weekmin = Math.min(...chartdatay)
    const weekmax = Math.max(...chartdatay)
    return [periodtotal.toFixed(decimal_places), weekmin.toFixed(decimal_places), weekmax.toFixed(decimal_places)]
  },

  // parameter: valuein , unitsin , unitsout
  // return converted value
  ConvertUnits: function(valuein , unitsin , unitsout ) {
    let valueout
    // Pounds per square inch of air pressure in a well airtube
    if (unitsin == 'psi') {
      if (unitsout == 'gal') {
        valueout = valuein * 2.31 * 1.05 // based on 6" well diameter
          return valueout
      } }
    // Cubic ft of natural gas / propane flowing from a tank
    if (unitsin =='cft') {
      if (unitsout == 'gal') {
        valueout = valuein * .012 // 82.62 cft in a gallon of natural gas
        return valueout
      } }
  return valuein // return what went in unless there was a successful conversion
  },

  // Parameters: two objects to compare for equality
  // return true/false
  ShallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  },

} // end - methods
} // end - export default
