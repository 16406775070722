  /* eslint-disable */
  import axios from 'axios';
  const url = 'api/datalog/';
  
  class DatalogService {

    // Get Row Count of Datalog Data entries for a datasource, between startdate and enddate
    static getRowCountByDateUid(startdate, enddate, datasourceuid) {
      return new Promise(async (resolve, reject) => {
        try {
          // debugger
          const res = await axios.get(url+'dtrowcountdateuid',{
            params: {
              start:startdate,
              end:enddate,
              datasourceuid:datasourceuid}
            });
          const data1 = res.data
  
          // console.log('data1 (daytotal)= ',data1)
          resolve(
            data1.data
          )  
        } catch(err) {
          reject(err);
        }
      });
    }
  
    // Get the specified number of most recent Entries of Datalog Data
    static getMostRecentEntries(numentries, datasourceuid) {
      return new Promise(async (resolve, reject) => {
        try {
          // debugger
          const res = await axios.get(url+'dtmostrecentn',{
            params: {
              numentries:numentries,
              datasourceuid:datasourceuid}
            });
          const data1 = res.data
          // console.log('data1 (most recent entries)= ',data1)
          resolve(
            data1.data
          )  
        } catch(err) {
          reject(err);
        }
      });
    }
    
    // Get All Entries of Datalog Data between startdate and enddate
    static getEntries(startdate, enddate, datasourceuid) {
      return new Promise(async (resolve, reject) => {
        try {
          // debugger
          const res = await axios.get(url+'dtrangeentries',{
            params: {
              start:startdate,
              end:enddate,
              datasourceuid:datasourceuid}
            });
          const data1 = res.data
  
          // console.log('data1 (daytotal)= ',data1)
          resolve(
            data1.data
          )  
        } catch(err) {
          reject(err);
        }
      });
    }
    
    // Get Aggregates computed from All Entries of Datalog Data between startdate and enddate
    static getAggregates(startdate, enddate, datasourceuid) {
      return new Promise(async (resolve, reject) => {
        try {
          // debugger
          const res = await axios.get(url+'dtaggregates',{
            params: {
              start:startdate,
              end:enddate,
              datasourceuid:datasourceuid}
            });
          const data1 = res.data
  
          // console.log('data1 (daytotal)= ',data1)
          resolve(
            data1.data
          )  
        } catch(err) {
          reject(err);
        }
      });
    }

    // Get Hourly Sums of All Entries in Datalog Table for a specific date
    // This version returns EVERY HOUR - those having no entries have sum of zero
    // getHourlySums is included for compatibility - original works the same
    static getHourlySums(startdate, datasourceuid) {
      return new Promise(async (resolve, reject) => {
        try {
          // debugger
          const res = await axios.get(url+'dthourlysums0',{
            params: {
              start:startdate,
              datasourceuid:datasourceuid}
            });
          const data1 = res.data
  
          // console.log('data1 (daytotal)= ',data1)
          resolve(
            data1.data
          )  
        } catch(err) {
          reject(err);
        }
      });
    }

    //*****************
    // Get Sum and Count of All Entries in Datalog Table for a specific timestamp range.
    // 
    //*****************
    static getDateRangeSum(starttimestamp, endtimestamp, datasourceuid) {
      return new Promise(async (resolve, reject) => {
        try {
          // debugger
          const res = await axios.get(url+'dtrangesum',{
            params: {
              starttimestamp:starttimestamp,
              endtimestamp:endtimestamp,
              datasourceuid:datasourceuid}
            });
          const data1 = res.data
  
          // console.log('data1 (daytotal)= ',data1)
          resolve(
            data1.data
          )  
        } catch(err) {
          reject(err);
        }
      });
    }


    // Get Daily Sums of All Entries in Datalog Table for a specific date range
    // This version returns EVERY DAY - those having no entries have sum of zero
    static getDailySums0(startdate, enddate, datasourceuid) {
      return new Promise(async (resolve, reject) => {
        try {
          // debugger
          const res = await axios.get(url+'dtrangedailysums0',{
            params: {
              start:startdate,
              end:enddate,
              datasourceuid:datasourceuid}
            });
          const data1 = res.data
  
          // console.log('data1 (daytotal)= ',data1)
          resolve(
            data1.data
          )  
        } catch(err) {
          reject(err);
        }
      });
    }

    // Get Daily Sums of All Entries in Datalog Table for a specific date range
    // This version returns ONLY THOSE DAYS THAT HAVE AT LEAST ONE ENTRY
    static getDailySums(startdate, enddate, datasourceuid) {
      return new Promise(async (resolve, reject) => {
        try {
          // debugger
          const res = await axios.get(url+'dtrangedailysums',{
            params: {
              start:startdate,
              end:enddate,
              datasourceuid:datasourceuid}
            });
          const data1 = res.data
  
          // console.log('data1 (daytotal)= ',data1)
          resolve(
            data1.data
          )  
        } catch(err) {
          reject(err);
        }
      });
    }

    // Get Daily Cumulative and Burndown Values of All Entries in Datalog Table for a specific date range
    static getDailyCumulativeValues(startdate, enddate, datasourceuid, startvalue) {
        return new Promise(async (resolve, reject) => {
        try {
          // debugger
          const res = await axios.get(url+'dailycumulativevalues',{
            params: {
              startdate,
              enddate,
              datasourceuid,
              startvalue}
            });
          const data1 = res.data
  
          // console.log('data1 (daytotal)= ',data1)
          resolve(
            data1.data
          )  
        } catch(err) {
          reject(err);
      }});
    }

    // Get Weekly Sums of All Entries in Datalog Table for a specific date range
    static getWeeklySums(startdate, enddate, datasourceuid) {
      return new Promise(async (resolve, reject) => {
        try {
          // debugger
          const res = await axios.get(url+'dtrangeweeklysums',{
            params: {
              start:startdate,
              end:enddate,
              datasourceuid:datasourceuid}
            });
          const data1 = res.data
  
          // console.log('data1 (daytotal)= ',data1)
          resolve(
            data1.data
          )  
        } catch(err) {
          reject(err);
        }
      });
    }

    // Get Monthly Sums of All Entries in Datalog Table for a specific date range
    static getMonthlySums(startdate, enddate, datasourceuid) {
      return new Promise(async (resolve, reject) => {
        try {
          // debugger
          const res = await axios.get(url+'dtrangemonthlysums',{
            params: {
              start:startdate,
              end:enddate,
              datasourceuid:datasourceuid}
            });
          const data1 = res.data
  
          // console.log('data1 (daytotal)= ',data1)
          resolve(
            data1.data
          )  
        } catch(err) {
          reject(err);
        }
      });
    }

    // Create a new Datalog entry  
    static createEntry(savetime, value, datasourceuid) {
      return new Promise(async (resolve, reject) => {
        try {
          // debugger
          const res = await axios.get(url+'createentry',{
            params: {
              savetime:savetime,
              value:value,
              datasourceuid:datasourceuid
             }
            });
          const data1 = res.data
          // console.log('data1 (hour totals)= ',data1)
          resolve(
            data1.data
          )  
        } catch(err) {
          console.log("Datalog Service err= ",err)
          reject(err);
        }
      });
    }
    // Create multiple new Datalog entries  
    static createMultiple(entries) {
      return new Promise(async (resolve, reject) => {
        try {
          // debugger
          const jsonentries = JSON.stringify(entries)
          const res = await axios.get(url+'createmultiple',{
            params: {
              jsonentries:jsonentries,
              }
            });
          const data1 = res.data
          // console.log('data1 (hour totals)= ',data1)
          resolve(
            data1.data
          )  
        } catch(err) {
          console.log("Datalog Service err= ",err)
          reject(err);
        }
      });
    }
  
    static updateEntry(valueid, savetime, value, datasourceuid) {
      return new Promise(async (resolve, reject) => {
        try {
          // debugger
          const res = await axios.get(url+'updateentry',{
            params: {
              valueid:valueid,
              savetime:savetime,
              value:value,
              datasourceuid:datasourceuid
             }
            });
          const data1 = res.data
          // console.log('data1 (hour totals)= ',data1)
          resolve(
            data1.data
          )  
        } catch(err) {
          console.log("Datalog Service err= ",err)
          reject(err);
        }
      });
    }
  
    static deleteEntry(valueid) {
      return new Promise(async (resolve, reject) => {
        try {
          // debugger
          const res = await axios.get(url+'deleteentry',{
            params: {
              valueid:valueid,
              }
            });
          const data1 = res.data
          // console.log('data1 (hour totals)= ',data1)
          resolve(
            data1.data
          )  
        } catch(err) {
          console.log("Datalog Service err= ",err)
          reject(err);
        }
      });
    }

     
  }
  export default DatalogService;