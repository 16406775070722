<!-- eslint-disable -->
<template>
  <v-container fluid>
    <v-toolbar-title>Select CSV File to Import (to Datalog)</v-toolbar-title>
  <v-row no-gutters>
    <v-col cols="8">
    <v-file-input
      label="File input"
      accept=".csv"
      outlined
      dense
      v-model="chosenFile"
      append-outer-icon="mdi-help"
      @click:append-outer="ShowHelp"
    ></v-file-input>
    </v-col>
    <v-col>
      <template>
        <vueprogress :progress="progress" :legendValue="legendValue" :size="progress_ellipse_size" v-if="showinsertdbentriesbutton"> </vueprogress>
      </template>
    </v-col>
  </v-row> 
  <v-row no no-gutters>
    <v-btn
      v-if="showdbentriesbutton"
      color="green lighten-2"
      dark
      small 
      class="mt-2 ml-4"
      @click='MostRecentDBEntries'
      >
      Show Recent DB Entries
    </v-btn>
    <v-btn
      v-if="showinsertdbentriesbutton"
      color="green lighten-2"
      dark
      medium 
      class="ml-4"
      @click='InsertCSVintoDatalog'
      >
      Insert CSV Entries to Database
    </v-btn>
    <v-btn
      color="red"
      text 
      @click='debuginstance++'
      >
      Debug Instance
    </v-btn>
  </v-row>   
<!-- *********** DATA TABLES HERE ************ -->
 <v-data-table
    v-if="showcsvdatatable"
    dense
    :headers="csvheaders"
    :items="csvdatatable_data"
    :footer-props="{
      'items-per-page-options':[15, 30, 50, -1]
    }"
    group-by="Datasource"
    sort-by="Timestamp"
    :sort-desc="sortdesc1"
    :item-class="row_classes"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>CSV File Data Preview ({{ chosenFile.name }})</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
  </v-data-table>

 <v-data-table
    v-if="showdbentriesdatatable"
    dense
    :headers="dbheaders"
    :items="dbdatatable_data"
    :footer-props="{
      'items-per-page-options':[15, 30, 50, -1]
    }"
    group-by="Datasource"
    sort-by="Timestamp"
    :sort-desc="sortdesc1"
    :item-class="row_classes"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Most Recent Corresponding Database Entries</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
  </v-data-table>

  </v-container>
</template>

<script>

/* eslint-disable */
//************************
// JAVASCRIPT
//************************
import DataSourceService from '../../services/DataSourceService'
import helperMixin from '@/mixins/helperMixin'
import moment from 'moment'
import DatalogService from '../../services/DatalogService'
import ConfigService from '../../services/ConfigService'
const csv=require('csvtojson')

export default {
  name: 'datalogimport',
  components: {  },
  mixins: [helperMixin],

//************************
// DATA
//************************
  data () { return {
    progress: 0,
    progress_ellipse_size: 100,
    legendValue: 0,
    csvheaders: [
      { text: 'Timestamp',sortable: true, value: 'Timestamp', class: "black--text subtitle-2", align: "start"}, 
      { text: 'Value (CSV)',sortable: true, value: 'Value', class: "black--text subtitle-2", align: "start"}, 
      { text: 'Datasource Name',sortable: true, value: 'Datasource', class: "black--text subtitle-2", align: "start"}, 
      { text: 'Datasource Uid',sortable: true, value: 'DataSourceUid', class: "black--text subtitle-2", align: "start"}, 
      ],
    dbheaders: [
      { text: 'Timestamp',sortable: true, value: 'Timestamp', class: "black--text subtitle-2", align: "start"}, 
      { text: 'Value (DB)',sortable: true, value: 'Value', class: "black--text subtitle-2", align: "start"}, 
      { text: 'Datasource Name',sortable: true, value: 'Datasource', class: "black--text subtitle-2", align: "start"}, 
      { text: 'Datasource Uid',sortable: true, value: 'DataSourceUid', class: "black--text subtitle-2", align: "start"}, 
      ],
    
    csvdatatable_data: [],
    dbdatatable_data: [],
    debuginstance: 0,
    showfilters: null,
    filterbtntext: null,
    chosenFile: null,
    csvdata: null,
    JSONdata: null,  
    dsuidnames: null,
    showdbentriesbutton: false,  
    showinsertdbentriesbutton: false,
    showcsvdatatable: false,
    showdbentriesdatatable: false, 
    sortdesc1: [true],   
    sortdesc2: [true]   
    }
  }, // End of data section

//************************
// WATCH
//************************
watch: {
  debuginstance (val) {
debugger
  },
  sel_drange (val) {
    // debugger
  },

  chosenFile (val) {
    if (!val) return
    if (val.name.length > 0) {
      this.ImportCSVFile()
    }  
  },
}, // end Watch section

//************************
// METHODS
//************************
methods: {

  forceRerender1 () {
    this.datalogentryformkey += 1
  },
  togglefilters() {
    this.showfilters = !this.showfilters
    this.filterbtntext = this.showfilters ? 'Show Filters' : 'Hide Filters'
  },
  savefilters () {
    // TBD Here we need to make a db query to save the current filter settings as
    //  default for when the page loads.
  },
  row_classes(item) {
    let classes = []
    if (item.Conflict) {
    classes.push("red--text font-italic") }
    return classes 
  },
  ShowHelp() {
    alert('jackpot')
  },

  // Read CSV File into JSON format in prep for possible import to DB
  ImportCSVFile() {
  try {
    if (!this.chosenFile) {this.data = "No File Chosen"}
    this.showdbentriesbutton = false
    this.showdbentriesdatatable = false
    this.showinsertdbentriesbutton = false
    this.showcsvdatatable = false
    let csvdatatable_data = []
    let JSONdata = null
    let newrow = null
    var reader = new FileReader();
    // Use the javascript reader object to load the contents
    // of the csv file, then postprocess to add DataSource Name
    reader.readAsText(this.chosenFile);
    reader.onload = () => {
      this.csvdata = reader.result;
      // console.log("csvdata =",this.csvdata)
      csv({ output:"json"})
      .fromString(this.csvdata)
      .then(async (result) => {
        JSONdata = result
        // console.log("JSONdata = ", JSONdata)
        const distinctuids = [...new Set(JSONdata.map(x => x.DataSourceUid))]
        let dsuidnames = []
        let ds = null
        // Get the datasource names from the DB
        for (let i=0; i<distinctuids.length; i++) {
          ds = await DataSourceService.dsGetbyUid(distinctuids[i])
          if (ds == null) {
            alert("Datasource Uid error in .CSV file!  Can't import this file.")
            this.chosenFile = null
            return
          }
          dsuidnames.push({uid: ds.dataSourceUid, name: ds.dataSourceName})
        }
        this.dsuidnames = dsuidnames
        // Weave in Datasource name to the table data
        for (let i=0; i<JSONdata.length; i++) {
          for (let j=0; j< dsuidnames.length; j++) {
            if (JSONdata[i].DataSourceUid == dsuidnames[j].uid) {
              newrow = { Timestamp: moment(JSONdata[i].Timestamp).format('YYYY-MM-DD HH:mm:ss'), Value: parseInt(JSONdata[i].Value).toString(), DataSourceUid: JSONdata[i].DataSourceUid, Datasource: dsuidnames[j].name }
              break; } 
          }
          csvdatatable_data.push(newrow)
        }
        this.csvdatatable_data = csvdatatable_data
        this.showcsvdatatable = true
        this.showdbentriesbutton = true
      } )
    }
  } catch(err) {
      this.error = err.message
      console.log('DatalogImport - ImportCSVFile: err= ', this.error)
  }
  },

  // Read in MR entries for the 
  async MostRecentDBEntries() {
    try {
      const dbdatatable_data = []
      const dsuidnames = this.dsuidnames
      let newrow = null
      for (let i=0; i<dsuidnames.length; i++) {
        const mrdbentries = await DatalogService.getMostRecentEntries(15, dsuidnames[i].uid)
        if (mrdbentries == null) continue
        for (let j=0; j<mrdbentries.length; j++) {
            newrow = { Timestamp: moment(mrdbentries[j].saveTime).format('YYYY-MM-DD HH:mm'), Value: parseInt(mrdbentries[j].value).toString(), DataSourceUid: dsuidnames[i].uid, Datasource: dsuidnames[i].name }
            dbdatatable_data.push(newrow)
            } 
          }
      this.dbdatatable_data = dbdatatable_data
      this.showdbentriesdatatable = true
      this.HighlightCSVDBConflicts()

      // this.$swal('Hello Vue World')

    } catch (err) {
      this.error = err.message
      console.log('DatalogImport - MostRecentDBEntries: err= ', this.error)
    }
  },

// Highlight All conflicts (CSV data that is already in the database)
  HighlightCSVDBConflicts () {
    let csvdatatable_data = this.csvdatatable_data
    let totalconflicts = 0
    csvdatatable_data.forEach(function (element) { element.Conflict = false})
    let dbdatatable_data = this.dbdatatable_data
    dbdatatable_data.forEach(function (element) { element.Conflict = false})
    for (let i=0; i<csvdatatable_data.length; i++) {
      for (let j=0; j<dbdatatable_data.length; j++) {
        if (this.ShallowEqual(csvdatatable_data[i], dbdatatable_data[j])) {
          csvdatatable_data[i].Conflict = true
          dbdatatable_data[j].Conflict = true
          totalconflicts++
        }
      }
    }
    if (totalconflicts == 0) {
      this.showdbentriesbutton = false
      this.showinsertdbentriesbutton = true
    } else {
      this.showdbentriesbutton = true
      this.showinsertdbentriesbutton = false
      alert("Conflicts between CSV entries and Datalog!")
    }
    // console.log ('csvdatatable_data = ', csvdatatable_data)
    // console.log ('dbdatatable_data = ', dbdatatable_data)
  },

// Insert CSV datatable entries into Datalog
// <TBD> add progress bar
  async InsertCSVintoDatalog() {
    try {
      // strip down unneeded properties 
      let csvdatatable_data = this.csvdatatable_data.map(o => ({savetime: o.Timestamp, datasourceuid: o.DataSourceUid, value: o.Value}))
      let csvdatabuf = 0
      let progress = 0
      let numcopied = 0
      // Insert chunks of import records into datalog
      do {
        csvdatabuf = csvdatatable_data.slice(numcopied, numcopied + 25)
        const dbdata_inserted = await DatalogService.createMultiple(csvdatabuf)
        numcopied += csvdatabuf.length
        progress = (numcopied/csvdatatable_data.length)*100
        this.progress = progress
        this.legendValue = numcopied
        // debugger
      } while(numcopied < csvdatatable_data.length)
      this.showinsertdbentriesbutton = false
      this.showcsvdatatable = false
      this.showdbentriesbutton = false
      this.showdbentriesdatatable = false
      this.chosenFile = null
      // debugger
      alert("Inserted " + numcopied.toString() + " Records into Datalog!")

    } catch (err) {
      this.error = err.message
      console.log('DatalogImport - InsertCSVintoDatalog: err= ', this.error)
    }
  },

}, // End Methods section

//************************
// LIFECYCLE HOOKS
//************************
created () {
//  debugger
},

} // Export default end bracket

</script>