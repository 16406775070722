  /* eslint-disable */
  import axios from 'axios';

  const url = 'api/config/';
  
  class ConfigService {

//*****************
// Return config variables
//*****************
static settingsGetOverview () {
    return new Promise(async (resolve, reject) => {
      // debugger
      try {
        const res = await axios.get(url+'overview', {
        });
        const data1 = res.data
        // console.log('data1 (ConfigService)= ',data1)
        resolve(
          data1.data
        )  
      } catch(err) {
        reject(err);
      }
    });
  }
}
export default ConfigService;